<template>
	<div class="mainTem">
		<!-- <div class="buyerHeader">
      <h3 class="buyerHeaderTit"><span>{{$t('i18nn_9c7e841fa06de079')}}</span>
        <el-tooltip effect="dark" :content="$t('btn.long_Export_excel')"ng_Export_excel')" placement="top">
          <el-button type="warning" circle icon="el-icon-download" size="mini" @click="exportExcel()"></el-button>
        </el-tooltip>
      </h3>
    </div> -->

		<div class="tableConTop">
			<el-row>
				<el-col :span="5" class="tableConTopLeft">
					<h3>
						<span class="tct_tit">{{$t('i18nn_9c7e841fa06de079')}}</span>
						<el-tooltip effect="dark" :content="$t('i18nn_ff6fd823ffab7e6b')" placement="top">
							<el-button type="warning" circle icon="el-icon-download" size="mini" @click="exportExcel()">
							</el-button>
						</el-tooltip>
					</h3>
				</el-col>
				<el-col :span="19" class="tableConTopRig">
					<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
					</el-button>
				</el-col>
			</el-row>
		</div>
		<!-- <div style="text-align: center; margin-top: 10px;">
      <el-button-group>
        <el-button type="primary"  size="medium" style="width: 200px;">{{$t('i18nn_5b14580d813b4a45')}}</el-button>
        <el-button type="primary" plain size="medium" style="width: 200px;" @click="toPageUrl('PartnerBillSettlementDet')">{{$t('i18nn_1b613e926a1e65dd')}}</el-button>
      </el-button-group>
    </div> -->
		<!-- <div class="formMsgCon">
        <h3><i class="titleIcon el-icon-bell"></i>{{$t('i18nn_41413cddfa61538c')}}</h3>
        <div class="formMsgDet">
          <p>
            此为 我的费用明细
          </p>
        </div>
    </div> -->
		<!--  <div class="buyerDataHeader">
      <el-button type="primary" plain size="small" @click="openDioalog(null)">{{$t('i18nn_24c8f46012a25c89')}}</el-button>
    </div> -->
		<div class="filterCon" style="">
			<div class="filterBarList">

			</div>
			<div class="filterAction">
				<!-- <ul class="actionConList"> -->

				<!-- </ul> -->
				<ul class="filterConList">
					<li>
						<span><span style="color:red;">*</span>{{$t('i18nn_c944a6686d996ab3')}}</span>
						<whNoSelect @changeData="changWhNo"></whNoSelect>
					</li>
					<li>
						<span><span style="color:red;">*</span>{{$t('i18nn_63ee7265156e13ac')}}</span>
						<el-select filterable :clearable="false" size="small" v-model="filterData.seqFomat"
							:placeholder="$t('FormMsg.Please_select')" style="width: 100px;" @change="serPageData()">
							<el-option v-for="item in selectOption.seqFomat" :key="item.value" :label="item.label"
								:value="item.value"></el-option>
						</el-select>
					</li>
					<li>
						<div class="" v-if="'d' == filterData.seqFomat">
							<span><span style="color:red;">*</span>{{$t('i18nn_d42355e6aef72227')}}</span>
							<el-date-picker v-model="filterData.daterange" size="small" type="daterange" align="right"
								:clearable="false" unlink-panels range-separator="-" start-placeholder="start date"
								end-placeholder="end date" :picker-options="pickerOptions" format="yyyy-MM-dd"
								value-format="yyyy-MM-dd" @change="serPageData()"></el-date-picker>
						</div>
						<div class="" v-else-if="'m' == filterData.seqFomat">
							<span>
								<span style="color:red;">*</span>
								{{ $t('i18nn_d42355e6aef72227') }}
							</span>
							<el-date-picker v-model="filterData.daterange2" size="small" type="monthrange" align="right"
								:clearable="false" unlink-panels range-separator="-" start-placeholder="start date"
								end-placeholder="end date" :picker-options="pickerOptions2" format="yyyy-MM"
								value-format="yyyy-MM" @change="serPageData()"></el-date-picker>
						</div>
						<div v-else-if="'y' == filterData.seqFomat">
							<span>
								<span style="color:red;">*</span>
								{{ $t('i18nn_d42355e6aef72227') }}
							</span>
							<el-date-picker
								v-model="filterData.daterange3"
								size="small"
								type="monthrange"
								align="right"
								:clearable="false"
								unlink-panels
								range-separator="-"
								start-placeholder="start date"
								end-placeholder="end date"
								:picker-options="pickerOptions2"
								format="yyyy"
								value-format="yyyy"
								@change="initData()"
							></el-date-picker>
						</div>
					</li>
					<li>
						<span>{{$t('i18nn_184333c81babf2f1')}}</span>
						<el-select filterable :clearable="true" size="small" v-model="filterData.opType"
							:placeholder="$t('FormMsg.Please_select')" style="width: 100px;" @change="serPageData()">
							<el-option v-for="item in selectOption.opType" :key="item.value" :label="item.label"
								:value="item.value"></el-option>
						</el-select>
					</li>
					<li>
						<span>SKU</span>
						<el-input :placeholder="$t('FormMsg.Please_Enter')" size="small" v-model="filterData.goodsSku"
							maxlength="50" :clearable="true" style="width: 250px;"></el-input>
					</li>
					<li>
						<span>{{$t('i18nn_f551589093f4bbfb')}}</span>
						<el-input :placeholder="$t('FormMsg.Please_Enter')" size="small"
							v-model="filterData.goodsSkuLike" maxlength="50" :clearable="true" style="width: 200px;">
						</el-input>
					</li>
					<li>
						<el-button icon="el-icon-search" size="small" type="primary" @click="initData()"></el-button>
					</li>
				</ul>
			</div>
		</div>

		<!-- <div class="wh-filter-con">
			<div class="wh-filter-item">
			  <span><span style="color:red;">*</span>{{$t('i18nn_c944a6686d996ab3')}}</span>
				<whNoSelect @changeData="changWhNo"></whNoSelect>
			</div>
			<div class="wh-filter-item">
			  <span><span style="color:red;">*</span>{{$t('i18nn_63ee7265156e13ac')}}</span>
			  <el-select filterable  :clearable="false" size="small" v-model="filterData.seqFomat" :placeholder="$t('FormMsg.Please_select')" style="width: 100px;" @change="serPageData()">
			  	<el-option v-for="item in selectOption.seqFomat" :key="item.value" :label="item.label" :value="item.value"></el-option>
			  </el-select>
			</div>
			
      <div class="wh-filter-item" v-if="'d' == filterData.seqFomat">
        <span><span style="color:red;">*</span>{{$t('i18nn_d42355e6aef72227')}}</span>
        <el-date-picker
          v-model="filterData.daterange"
          size="small"
          type="daterange"
          align="right"
          :clearable="false"
          unlink-panels
          range-separator="-"
          start-placeholder="start date"
          end-placeholder="end date"
          :picker-options="pickerOptions"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
           @change="serPageData()"
        ></el-date-picker>
      </div>
			<div class="wh-filter-item" v-else-if="'m' == filterData.seqFomat">
				<span>
					<span style="color:red;">*</span>
					{{ $t('i18nn_d42355e6aef72227') }}
				</span>
				<el-date-picker
					v-model="filterData.daterange2"
					size="small"
					type="monthrange"
					align="right"
					:clearable="false"
					unlink-panels
					range-separator="-"
					start-placeholder="start date"
					end-placeholder="end date"
					:picker-options="pickerOptions2"
					format="yyyy-MM"
					value-format="yyyy-MM"
					@change="serPageData()"
				></el-date-picker>
			</div>
			
			</div> -->
		<!-- <div class="wh-filter-con">
			<div class="wh-filter-item">
			  <span>{{$t('i18nn_184333c81babf2f1')}}</span>
			  <el-select filterable :clearable="true" size="small" v-model="filterData.opType" :placeholder="$t('FormMsg.Please_select')" style="width: 100px;" @change="serPageData()">
			  	<el-option v-for="item in selectOption.opType" :key="item.value" :label="item.label" :value="item.value"></el-option>
			  </el-select>
			</div>
			
      <div class="wh-filter-item">
        <span>SKU</span>
        <el-input :placeholder="$t('FormMsg.Please_Enter')" size="small" v-model="filterData.goodsSku" maxlength="50" :clearable="true" style="width: 250px;"></el-input>
      </div>
			<div class="wh-filter-item">
			  <span>{{$t('i18nn_f551589093f4bbfb')}}</span>
			  <el-input :placeholder="$t('FormMsg.Please_Enter')" size="small" v-model="filterData.goodsSkuLike" maxlength="50" :clearable="true" style="width: 200px;"></el-input>
			</div>
      <el-button type="primary" @click="serPageData" size="small">{{ $t('FormMsg.Search') }}</el-button>
    </div> -->

		<div class="" v-loading="loading_load" :element-loading-text="$t('tips.loading')">
			<!-- <div class="filterCon">
        <el-row>
          <el-col :span="10" class="sellerHeaderMsg" >
            &nbsp;
          </el-col>
          <el-col :span="6" class="sellerHeaderMsg" >
            &nbsp;
          </el-col>
          <el-col :span="6">
            <span>{{$t('i18nn_a4eca6ff2588d791')}}</span>
            <el-input :placeholder="$t('FormMsg.Please_Enter')" size="small" v-model="filterData.billNo" maxlength="50" :clearable="true" style="width: 150px;">
            </el-input>
          </el-col>
          <el-col :span="2">
            <el-button type="primary" @click="serPageData" size="small">{{$t('FormMsg.Search')}}</el-button>
          </el-col>
        </el-row>
      </div> -->
			<el-table :data="tableData" stripe :border="true" style="width: 100%" size="small"
				:max-height="$store.state.frameConHeightWh1">
				<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center"
					:label="$t('Storage.tableColumn.no')"></el-table-column>

				<!-- <el-table-column prop="cusName" :label="$t('i18nn_18d7892498938435')"></el-table-column> -->
				<el-table-column prop="whNo" :label="$t('i18nn_5acbec83efb27445')"></el-table-column>
				<el-table-column prop="opTypeName" :label="$t('i18nn_184333c81babf2f1')"></el-table-column>

				<el-table-column prop="goodsSku" :label="'SKU'"></el-table-column>

				<el-table-column prop="stock" :label="$t('i18nn_2b2633c44fbf02b8')"></el-table-column>
				<el-table-column prop="seqDate" :label="$t('i18nn_716dcf4d38910c35')"></el-table-column>


			</el-table>
			<div class="tableCon_pagination">
				<hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination>
			</div>
		</div>
		<!-- 修改弹窗 -->
		<!-- <el-dialog :close-on-click-modal="false"  :title="$t('i18nn_62d1f13b854eb1f7')" append-to-body :visible.sync="dialogFormVisible">
      <el-form ref="form" :rules="formRules" :model="form" label-width="100px" v-loading="loading" :element-loading-text="$t('tips.Submitting')">

        <el-form-item label="用户账号：" prop="mobile">
          <el-input v-if="0==dialogFormStatus" v-model="form.mobile" :placeholder="$t('i18nn_29799f539ee6b906')" maxlength="11"></el-input>
          <el-input v-else v-model="form.mobile" maxlength="11" readonly></el-input>
        </el-form-item>

        <el-form-item label="用户姓名：" prop="subUserName">
          <el-input v-model="form.subUserName" :placeholder="$t('FormMsg.Please_Enter')"></el-input>
        </el-form-item>

        <el-form-item label="所属厂家：">
          <el-input v-model="UserInfo.enterpriseName" readonly></el-input>
        </el-form-item>

        <el-form-item label="是否有效：" prop="state">
          <el-switch
            v-model="form.state"
            :active-text="$t('i18nn_02ccd2cf723f9272')"
            :inactive-text="$t('i18nn_e76d885ae1c74d4f')">
          </el-switch>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" plain @click="dialogFormVisible = false">{{$t('FormMsg.Close')}}</el-button>
        <el-button type="primary" @click="submitForm('form')">{{$t('FormMsg.Save')}}</el-button>
      </div>
    </el-dialog> -->
		<!-- excel 导出排序 -->
		<whExcelCustom :openTime="excelOpenTime" :excelHead="excelHead" :excelData="expExcelData"
			:pagination="pagination" :excelName="excelName" :excelOption="excelOption"
			:expHttpUrl="$urlConfig.WhOutBill" :expHttpFilter="pageFilterData()"></whExcelCustom>
	</div>
</template>
<script>
	// import excelUtilsNew from '@/utils/excelUtilsNew.js';
	import whExcelCustom from '@/components/StorageCenter/components/whExcelCustom.vue';
	// import SelAgentUser from '@/components/Common/SelAgentUser.vue';
	import whNoSelect from '@/components/StorageCenter/components/whNoSelect.vue';
	export default {
		// name: 'BuyerOrder',
		//meta信息seo用
		// metaInfo: {
		//   title: '互易天下-厂家共享平台-买家中心-收货账单', // set a title
		//   meta: [{ // set meta
		//     name: '互易天下-买家中心-收货账单',
		//     content: '互易天下-厂家共享平台-买家中心-收货账单'
		//   }],
		//   // link: [{                 // set link
		//   //   rel: 'asstes',
		//   //   href: 'https://assets-cdn.github.com/'
		//   // }]
		// },
		// props: ['isDialog'],
		components: {
			// SelAgentUser
			whExcelCustom,
			whNoSelect
		},
		data() {
			return {
				//excel导出
				excelOpenTime: '',
				excelHead: [],
				expExcelData: [],
				excelName: '',
				excelOption: {},
				// activeName: 'second',
				// isShowFrom:false,
				pickerOptions: this.$PickerDate.pickerOptions_2(),
				pickerOptions2: {
					shortcuts: [{
							text: this.$t('i18nn_08ad990830d08ed0'),
							onClick(picker) {
								picker.$emit('pick', [new Date(), new Date()]);
							}
						},
						{
							text: this.$t('i18nn_e1a818330d2d1b44'),
							onClick(picker) {
								const end = new Date();
								const start = new Date(new Date().getFullYear(), 0);
								picker.$emit('pick', [start, end]);
							}
						},
						{
							text: this.$t('i18nn_9686abed4dde5fcc'),
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setMonth(start.getMonth() - 6);
								picker.$emit('pick', [start, end]);
							}
						}
					]
				},
				// UserInfo: this.$store.getters.getUserInfo,
				// dialogFormVisible: false,
				// dialogFormStatus: 0, //0-新增，1-修改
				loading: false,
				// form: {
				//   "mobile":"",//子账户账号（手机号）
				//   "subUserId": "", //  子账户id
				//   "userSubUserId": "", //主账户子账户关系表id
				//   "subUserName": "", // 子账户名称
				//   "state": true, //子账户状态，0-有效，1-无效
				// },

				// formRules: {
				//   mobile: [
				//     { required: true, message: this.$t('i18nn_29799f539ee6b906'), trigger: 'blur' },
				//     { pattern: this.$Validate.REGEX_PHONE_International, message: this.$t('i18nn_f5a9e0830d476523')}
				//   ],
				//   subUserId: [
				//     { required: true, message: this.$t('i18nn_4b7dc78116a958d3'), trigger: 'blur' },
				//   ],
				//   userSubUserId: [
				//     { required: true, message: '请输入主账户子账户关系表ID', trigger: 'blur' },
				//   ],
				//   subUserName: [
				//     { required: true, message: this.$t('i18nn_a47da648bd79c2fc'), trigger: 'blur' },
				//   ],
				//   state: [
				//     { required: true, message: this.$t('i18nn_3821259f4f037ccf'), trigger: 'blur' },
				//   ],

				// },
				// 类型 (1 收货  2 转运/自提出库  3 一件代发)  非必须

				selectOption: {
					opType: [{
						label: this.$t('i18nn_f762ff6f05589bdf'),
						value: "1"
					}, {
						label: "转运/自提出库",
						value: "2"
					}, {
						label: this.$t('i18nn_684a2afb069b6016'),
						value: "3"
					}],
					seqFomat: [{
						label: this.$t('i18nn_5d4c76c6431c06ef'),
						value: 'y'
					},{
						label: this.$t('i18nn_c232abd55b5719fb'),
						value: "m"
					}, {
						label: this.$t('i18nn_43d7de9d82345073'),
						value: "d"
					}],
					wh_no: [],
				},

				loading_load: false,
				tableData: [],
				//分页数据
				pagination: this.$Utils.defaultPagination(),
				//查询，排序方式
				filterData: {
					// orderBy: 'id_', //排序字段
					// sortAsc: 'desc', //desc降序，asc升序
					// agentUser:"",
					// "accountPeriod":"",
					daterange: [this.$Utils.fomatterDate_YYYYMMdd(new Date().getTime() - 3600 * 1000 * 24 * 30), this
						.$Utils.fomatterDate_YYYYMMdd(new Date())
					],
					daterange2: [this.$Utils.fomatterDate_YYYYMM(new Date().getTime() - 3600 * 1000 * 24 * 30 * 2), this
						.$Utils.fomatterDate_YYYYMM(new Date())
					],
					daterange3: [this.$Utils.fomatterDate_YYYY(new Date()), this.$Utils.fomatterDate_YYYY(new Date())],
					
					goodsSku: '',

					// startSeqDate 开始时间    必须

					// endSeqDate 结束时间    必须

					// goodsSku 商品编码   非必须

					whNo: "", // 仓库编码   必须

					opType: "", // 类型 (1 收货  2 转运/自提出库  3 一件代发)  非必须
					seqFomat: "y", //统计类型(m 按月  d 按天)
					goodsSkuLike: "", // 商品模糊     非必须

					// startTime: '', //"2021-05-05",
					// endTime: '' //"2021-06-05"
					// "billNo":""
				}
			};
		},

		//创建时
		created() {
			// this.getPageData();
			// this.getDicData();
		},
		//编译挂载前
		mounted() {
			// this.initData();
		},
		methods: {
			initData() {
				this.pagination.current_page = 1;
				// this.currentSel = {};
				// this.multipleSelection = [];
				if (!this.filterData.whNo) {
					this.$message.warning(this.$t('FormMsg.Select_long_wh'));
					return;
				}
				this.getPageData();

			},
			changWhNo(data) {
				console.log('changWhNo', data);
				this.filterData.whNo = data.code;
				// this.filterData.userId = data.userId;
				this.initData();
			},
			// changeAgentUser(val){
			// this.filterData.agentUser = val;
			// 	this.initData();
			// },
			//导出 excel
			exportExcel() {
				// <el-table-column prop="cusName" :label="$t('i18nn_18d7892498938435')"></el-table-column>
				// <el-table-column prop="whNo" :label="$t('i18nn_5acbec83efb27445')"></el-table-column>
				// <el-table-column prop="opTypeName" :label="$t('i18nn_184333c81babf2f1')"></el-table-column>

				// <el-table-column prop="goodsSku" :label="'SKU'"></el-table-column>

				// <el-table-column prop="stock" :label="$t('i18nn_2b2633c44fbf02b8')"></el-table-column>
				// <el-table-column prop="seqDate" :label="$t('i18nn_716dcf4d38910c35')"></el-table-column>
				let columns = [
					// {
					// 	title: this.$t('i18nn_18d7892498938435'),
					// 	key: 'cusName'
					// },
					{
						title: this.$t('i18nn_5acbec83efb27445'),
						key: 'whNo'
					},
					{
						title: this.$t('i18nn_184333c81babf2f1'),
						key: 'opTypeName'
					},
					{
						title: 'SKU',
						key: 'goodsSku'
					},
					{
						title: this.$t('i18nn_2b2633c44fbf02b8'),
						key: 'stock'
					},
					{
						title: this.$t('i18nn_716dcf4d38910c35'),
						key: 'seqDate'
					},

				];
				let Data = this.tableData;
				this.expExcelData = Data;
				this.excelHead = columns;
				this.excelName = 'wh_outBill';
				this.excelOption = {
					height: 20
				};
				this.excelOpenTime = new Date().getTime();
				// excelUtilsNew.exportExcel(columns, Data, 'wh_outBill',{height:20});
			},
			//跳转页面
			// toPageUrl(name){
			//   this.$router.push({'name':name});
			// },
			//打开新增编辑，弹窗
			// openDioalog(formParm) {
			//   // console.log(formParm);
			//   // this.dialogFormVisible = true;
			//   // let form = Object.assign({}, formParm);
			//   // console.log('form',form);
			//   // // 重置
			//   // this.resetForm('form');
			//   // if (null === formParm) { //新增

			//   //   this.dialogFormStatus = 0;
			//   //   form.subUserId = null;
			//   //   form.userSubUserId = null;
			//   //   form.state = true;
			//   //   //浅拷贝、对象属性的合并
			//   //   this.form = form;

			//   // } else { //修改
			//   //   this.dialogFormStatus = 1;
			//   //   form.state = form.state === '0' ? true : false;

			//   //   //浅拷贝、对象属性的合并
			//   //   this.form = form;

			//   // }
			//   this.$alert('确定要确认通过该笔账单！', this.$t('tips.tipsTitle'), {
			//         type: 'warning',
			//         //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//       });
			// },
			//点击状态为非出账弹出窗
			// noConfirm(){
			//   this.$alert(this.$t('i18nn_e741d17b1fd891c2')已出账this.$t('i18nn_d6f109b7bee2f33f')确认"操作，请核实！', this.$t('tips.tipsTitle'), {
			//         type: 'warning',
			//         //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//       });
			// },
			//提交信息
			// submitForm(formName) {

			//   this.$refs[formName].validate((valid) => {

			//     if (valid) {
			//       let formData = Object.assign({}, this.form);
			//       //浅拷贝、对象属性的合并
			//       if (0 === this.dialogFormStatus) {

			//         formData.id = null;

			//         this.postData(this.$urlConfig.subUserManaAdd, formData);
			//       } else {

			//         this.postData(this.$urlConfig.subUserManaUpdate, formData);
			//       }

			//     } else {
			//       console.log('error submit!!');
			//       this.$alert(this.$t("tips.checkSubmitData"), this.$t('tips.tipsTitle'), {
			//         type: 'warning',
			//         //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//       });
			//       return false;
			//     }
			//   });
			// },
			//重置输入框
			// resetForm(formName) {
			//   console.log(formName);
			//   // console.log(this.$refs[formName]);

			//   if (this.$refs[formName]) {
			//     this.$refs[formName].resetFields();

			//   } else {
			//     console.log('this.$refs[formName]',this.$refs[formName]);
			//   }
			// },

			//提交信息
			// postData(url, formData) {
			//   let _this = this;
			//   this.loading = true;

			//   formData.state = formData.state ? '0' : '1';
			//   this.$http.post(url, formData)
			//     .then(({ data }) => {
			//       console.log(this.$t('tips.submitSuccess'));
			//       console.log(data);
			//       
			//       this.loading = false;
			//       if (200 == data.code) {
			//         this.dialogFormVisible = false;
			//         this.getPageData();
			//         this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
			//           type: 'success',
			//           //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//         });
			//       } else {
			//         // if (!data.msg) {
			//         //   data.msg = this.$t('tips.submitError');
			//         // }
			//         this.$alert(data.msg?data.msg:this.$t("tips.submitError"), this.$t('tips.tipsTitle'), {
			//           type: 'warning',
			//           //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//         });
			//       }
			//     })
			//     .catch((error) => {
			//       console.log(error);
			//       console.log(this.$t("tips.submitError"));
			//       _this.loading = false;
			//       this.$alert(this.$t("tips.submitRequestError"), this.$t('tips.tipsTitle'), {
			//         type: 'warning',
			//         //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//       });
			//     });
			// },
			//查询数据
			serPageData() {
				this.pagination.current_page = 1;
				this.getPageData();
			},
			//分页的筛选项数据
			pageFilterData() {
				let daterange = this.filterData.daterange;
				if ('m' == this.filterData.seqFomat) {
					daterange = this.filterData.daterange2;
				} else if('y' == this.filterData.seqFomat){
					daterange = this.filterData.daterange3;
				}
				console.log('daterange', daterange);
				let startTime = '';
				let endTime = '';

				if (daterange && daterange.length >= 2) {
					startTime = daterange[0];
					endTime = daterange[1];
				} else {
					this.$message.warning(this.$t('tips.Please_Sel_date'));
				}
				return {
					// proxyUserId: this.filterData.agentUser ? this.filterData.agentUser : null,
					whNo: this.filterData.whNo ? this.filterData.whNo : null,
					startSeqDate: startTime ? startTime : null,
					endSeqDate: endTime ? endTime : null,
					opType: this.filterData.opType ? this.filterData.opType : null,
					seqFomat: this.filterData.seqFomat ? this.filterData.seqFomat : null,
					goodsSkuLike: this.filterData.goodsSkuLike ? this.filterData.goodsSkuLike : null,
					goodsSku: this.filterData.goodsSku ? this.filterData.goodsSku : null
				};
			},
			//请求分页数据
			getPageData() {
				let filterData = Object.assign({
						offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
						limit: this.pagination.page_size, //当前页显示数目
					},
					this.pageFilterData()
				);

				this.loading_load = true;
				this.$http
					.put(this.$urlConfig.WhOutBill, filterData)
					.then(({
						data
					}) => {
						console.log(this.$store.getters.getUserInfo);
						console.log('分页，请求成功');
						console.log(data);
						
						this.loading_load = false;
						//表格显示数据
						this.tableData = data.rows;
						//当前数据总条数
						this.pagination.total = parseInt(data.total);
						//当前页数
						// this.pagination.current_page = parseInt(data.current);
						//当前页条数
						// this.pagination.page_size = parseInt(data.size);
					})
					.catch(error => {
						console.log(error);
						console.log('账单分页，请求失败');
						this.loading_load = false;
					});
			},
			//操作
			// sureBillAction(row,msg,state) {
			//   console.log(this.$t('i18nn_4f5bb4ff8b3d804b'), row);
			//   console.log(row);
			//   this.$confirm(this.$t('i18nn_e8ec92802315a287')+msg+'通过该笔账单?', this.$t('tips.tipsTitle'), {
			//     //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//     //cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
			//     type: 'warning'
			//   }).then(() => {
			//     //确认
			//     let dataParm = {
			//       ids:[row.id],
			//       state:state,
			//     }
			//     this.updatePageData(dataParm, msg);
			//   }).catch(() => {

			//   });

			// },
			//更新特定数据,
			// updatePageData(dataParm, msg) {
			//   // let _this = this;
			//   this.loading_load = true;
			//   //浅拷贝、对象属性的合并
			//   let dataParmAss = Object.assign({}, dataParm);
			//   // dataParmAss.isDefault = 1;
			//   this.$http.post(this.$urlConfig.PartnerMatchingFeeModifyPage, dataParmAss)
			//     .then(({ data }) => {
			//       console.log(msg + "特定数据，请求成功");
			//       console.log(data);
			//       if (200 == data.code) {
			//         this.$message.success(msg + '成功！');
			//         this.getPageData();
			//       } else {
			//         this.$message.warning(data.msg ? data.msg : msg + '失败,请重试');
			//       }
			//     })
			//     .catch((error) => {
			//       console.log(error);
			//       console.log("更新特定数据，请求失败");
			//       this.loading_load = false;
			//       this.$message.warning('' + msg + '失败,请重试！');
			//     });
			// }
			//查询数据字典
			// getDicData() {
			// 	// this.loading_load = true;
			// 		this.$http
			// 			.put(this.$urlConfig.HyDicQueryList, ['wh_no'])
			// 			.then(({ data }) => {
			// 				console.log('查询数据字典，请求成功');
			// 				console.log(data);
			// 				if (200 == data.code && data.data) {
			// 					// this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
			// 					// this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
			// 					// this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
			// 					this.selectOption.wh_no = data.data['wh_no'];
			// 					// this.selectOption.wh_packing_type = data.data['wh_packing_type'];
			// 				} else {
			// 					if (!data.msg) {
			// 						data.msg = this.$t('tips.submitError');
			// 					}
			// 					this.$message.warning(data.msg);
			// 				}
			// 			})
			// 			.catch(error => {
			// 				console.log(error);
			// 				console.log('查询数据字典接口，请求失败');
			// 				this.$message.error(this.$t('tips.submitRequestError'));
			// 			});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
